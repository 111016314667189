import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { filter, from, map, mergeMap } from 'rxjs';
import { AppEpic } from '../store.config';
import { api } from '@/service/api';
import { IPartnerConfig, PARTNERS_CONFIGS } from '@/assets/config/common/partner.config';
type ENV = 'dev' | 'qat' | 'uat' | 'prd';

type ApiHostConfig = {
    env: ENV;
    host: string;
    vchat: string;
}

type Config = {
    apiHost: ApiHostConfig | undefined;
    partners: IPartnerConfig[];
};

const initialState: Config = {
    apiHost: undefined,
    partners: PARTNERS_CONFIGS,
};

// Async Actions
export const getApiConfig = createAction<undefined>('config/FETCH_API_CONFIG');

// Epics
export const fetchConfigEpic: AppEpic = (action$) =>
    action$.pipe(
        filter(getApiConfig.match),
        mergeMap(() => {
            return from(fetch(api.hostConfig.path, { method: api.hostConfig.method }).then<Config['apiHost']>((data) => data.json())).pipe(
                map((api) => updateApiHost(api)),
            );
        }),
    );

/**
 * Slice
 */
export const configSlice = createSlice({
    name: 'config',
    initialState,
    reducers: {
        updateApiHost(state, action: PayloadAction<Config['apiHost']>) {
            state.apiHost = action.payload;
            return state;
        },
    },
});

// Actions
export const {
    updateApiHost,
} = configSlice.actions;

/**
 * Reducer
 */
export default configSlice.reducer;
