import { useTranslation } from 'react-i18next';
import { closeDialog } from '@/store/slices/dialog.slice';
import { RootState } from '@/store/store.config';
import { CloseIcon, InfoIcon, WarningIcon, WarningTwoIcon } from '@chakra-ui/icons';
import { Button, Icon, IconButton } from '@chakra-ui/react';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export const Dialog = () => {
    // const [isOpen, setIsOpen] = useState(props.isOpen);
    const dialogStore = useSelector((state: RootState) => state.dialogStore);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const handleClose = () => {
        if (dialogStore.onClose) {
            dialogStore.onClose();
        }
        // setIsOpen(false);
        dispatch(closeDialog());
    };

    const getIcon = useCallback(
        (key: IButtonType) => {
            switch (key) {
                case DialogButtonType.Info:
                    return <Icon as={InfoIcon} w={5} h={5} color="blue.500" className="ml-1" />;
                case DialogButtonType.Warning:
                    return <Icon as={WarningIcon} w={5} h={5} color="red.500" className="ml-1" />;
                case DialogButtonType.Error:
                    return <Icon as={WarningTwoIcon} w={5} h={5} color="red.500" className="ml-1" />;
            }
        },
        [dialogStore.type],
    );

    let buttons: IDialogButtonSetting[];

    if (dialogStore.buttons && dialogStore.buttons.length > 0) {
        buttons = dialogStore.buttons.map((btn) => ({
            text: btn.text,
            onClick: () => {
                btn.onClick();
                if (btn.type === 'confirm') {
                    dispatch(closeDialog());
                } else {
                    handleClose();
                }
            },
        }));
    } else {
        buttons = [];
        if (dialogStore.onClose) {
            buttons.push({
                text: t('Lbl_Button_Close'),
                onClick: handleClose,
            });
        }
        if (dialogStore.onConfirm || buttons.length === 0) {
            buttons.push({
                text: t('Lbl_Button_Confirm'),
                onClick: () => {
                    if (dialogStore.onConfirm) {
                        dialogStore.onConfirm();
                    }
                    // setIsOpen(false);
                    dispatch(closeDialog());
                },
            });
        }
    }

    // useEffect(() => {
    //     setIsOpen(props.isOpen);
    // }, [props.isOpen]);

    const buttonsAlign = useCallback((align = 'end') => {
        switch (align) {
            case 'start':
                return 'justify-start';
            case 'center':
                return 'justify-center';
            case 'end':
                return 'justify-end';
            default:
                return 'justify-end';
        }
    }, []);

    if (dialogStore.isOpen) {
        return (
            <div className=" z-[2000] fixed w-screen h-screen flex justify-center drop-shadow-lg">
                <div className=" bg-gray-500 opacity-70 absolute top-0 right-0 left-0 bottom-0  "></div>
                <div className=" bg-gray-200 lg:w-[450px] w-[250px] min-w-[250px]  rounded-lg opacity-100 self-center z-40 ">
                    <div className="flex flex-col ">
                        <div className=" basis-3/12 rounded-t-lg text-lg bg-slate-600 text-gray-200 relative">
                            <div className="float-left">{getIcon(dialogStore.type)}</div>
                            <div className="absolute left-1/2 -translate-x-1/2">{dialogStore.title}</div>
                            <div className="float-right">
                                <IconButton colorScheme="linkedin" variant={'ghost'} aria-label="Close" size="sm" onClick={handleClose} icon={<CloseIcon />} />
                            </div>
                        </div>
                        <div className=" basis-7/12 m-2 container ">
                            <div>
                                {dialogStore.contentType === DialogContentType.Element ? (
                                    dialogStore.content
                                ) : (
                                    <p className="whitespace-pre-line">{dialogStore.content}</p>
                                )}
                            </div>
                        </div>
                        <div className={`basis-3/12 flex ${buttonsAlign(dialogStore.buttonsAlign)} pr-1 py-2 bg-slate-400 rounded-b-lg`}>
                            {buttons.map((bt, idx) => (
                                <Button
                                    size={'sm'}
                                    key={`dl_bt_${idx}_${bt.text}`}
                                    className="ml-2 mt-1"
                                    variant={'solid'}
                                    colorScheme="blackAlpha"
                                    onClick={() => bt.onClick()}>
                                    {bt.text}
                                </Button>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export enum DialogContentType {
    Text = 'Text',
    Element = 'Element',
}

export type IDialogProp = {
    title: string;
    content: string | JSX.Element;
    contentType?: DialogContentType;
    type: IButtonType;
    buttons?: IDialogButtonSetting[];
    isOpen: boolean;
    buttonsAlign?: 'start' | 'center' | 'end';
    onClose?: () => void;
    onConfirm?: () => void;
};

export const DialogButtonType = {
    Info: 'Info',
    Warning: 'Warning',
    Error: 'Error',
} as const;

export type IButtonType = keyof typeof DialogButtonType;

export type IDialogButtonSetting = {
    text: string;
    onClick: () => void;
    type?: 'cancel' | 'confirm';
};
