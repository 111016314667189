import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import I18NextHttpBackend from 'i18next-http-backend';

i18next
    .use(I18NextHttpBackend)
    .use(initReactI18next)
    .init({
        debug: process.env.NODE_ENV === 'development',
        supportedLngs: ['en-us', 'zh-cn', 'zh-tw'],
        lowerCaseLng: true,
        fallbackLng: 'zh-cn',
        load: 'currentOnly',
        interpolation: {
            prefix: '{',
            suffix: '}',
        },
        backend: {
            loadPath: '/locales/{lng}/{ns}.json',
            addPath: '/locales/add/{lng}/{ns}',
        },
    });
