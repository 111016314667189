export function getAppStorageUtil(): [
    <T>(key: STORAGE_KEYS_CONSTANT, value: T) => void,
    <T>(key: STORAGE_KEYS_CONSTANT) => T | null,
    (key: STORAGE_KEYS_CONSTANT) => void,
] {
    const setItem = <T>(key: STORAGE_KEYS_CONSTANT, value: T) => {
        const data = JSON.stringify(value);
        // console.log(`setItem: ${data}`);

        sessionStorage.setItem(key, data);
    };

    const getItem = <T>(key: STORAGE_KEYS_CONSTANT): T | null => {
        const data = sessionStorage.getItem(key);

        if (data === null) return null;

        return JSON.parse(data) as T;
    };

    const removeItem = (key: STORAGE_KEYS_CONSTANT) => {
        sessionStorage.removeItem(key);
    };

    return [setItem, getItem, removeItem];
}

export default getAppStorageUtil;

export const STORAGE_KEYS = {
    LOGIN_TOKEN: 'LOGIN_TOKEN',
    AGENTPARTNER_KEY: 'AGENTPARTNER_KEY',
    USERINFO: 'USERINFO',
    TOKEN: 'TOKEN',
    REGISTER_DATE: 'REGISTER_DATE',
    TIMESTAMP: 'TIMESTAMP',
    ROLE_TYPE: 'ROLE_TYPE',
    PERMISSIONS: 'PERMISSIONS',
    VCHAT_ID: 'VCHAT_ID',
    USER_VCHAT_INFOS: 'USER_VCHAT_INFOS',
    HAS_RECHARGE_ITEMS: 'HAS_RECHARGE_ITEMS',
    TRANSACTION_TYPE_LIST: 'TRANSACTION_TYPE_LIST',
} as const;

export type STORAGE_KEYS_CONSTANT = keyof typeof STORAGE_KEYS;
