import { ITransactionType } from '@/models/transactionType';

export type ILogonModel = {
    userName: string;
    password: string;
};

export enum RoleTypeEnum {
    NormalUser,
    SuperUser,
}

export type ILogonResponse = {
    UserInfo: {
        Token: string;
        RegisterDate: string;
        RoleType: RoleTypeEnum;
        Permissions: number[];
        VChatId: string;
        TransactionTypes: ITransactionType[];
        Account: string;
    };
    AgentPartners: IAgentPartner[];
};

export type IAgentPartner = {
    PartnerId: number;
    AgentUserId: number;
    AgentUserCode: string;
    IsEnabled: boolean;
    NickName: string;
    Remark: string;
};
