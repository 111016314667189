import { Modal, ModalBody, ModalContent, ModalOverlay, Spinner, useDisclosure } from '@chakra-ui/react';
import { useEffect } from 'react';

type Props = {
    visible: boolean;
};
const LoadingMask = ({ visible }: Props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        visible ? onOpen() : onClose();
    }, [visible]);

    return (
        <>
            <Modal isCentered closeOnOverlayClick={false} size={'xs'} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay bg="blackAlpha.100" backdropFilter="auto" backdropBlur='2px' />
                <ModalContent bg="none" border="none" shadow="none">
                    <ModalBody className="flex justify-center">
                        <Spinner size="lg" className=" text-gray-400" />
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default LoadingMask;
