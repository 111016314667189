import { APIModel } from '@/models/apiRequest';
import { IResponse } from '@/models/entities/IResponse';
import { TableLayoutAPI } from '@/models/layout/tableLayout';
import { RootState } from '@/store/store.config';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import Axios from 'axios-observable';
import { pluckFirst, useObservable, useObservableCallback, useSubscription } from 'observable-hooks';
import { useSelector } from 'react-redux';
import { catchError, combineLatest, filter, iif, map, of, switchMap, withLatestFrom } from 'rxjs';

export interface IHandlerProps<T, R> {
    apiItem: ApiType;
    value: T;
    deps: R[];
}

interface IResponseHandlerProps<T, R, S> extends IHandlerProps<AxiosResponse<IResponse<R>, any>, S> {
    request: T;
}

type ApiType = APIModel | TableLayoutAPI | undefined;

export default function useFetchApi<T, R = any, S = any>(
    api: ApiType,
    requestHandler: (props: IHandlerProps<T, S>) => T,
    responseHandler: (props: IResponseHandlerProps<T, R, S>) => void,
    deps: S[] = [],
    errorHandler: (error: any, deps: S[]) => void = (error) => {},
) {
    const apiConfig = useSelector((state: RootState) => state.configStore.apiHost);
    const host$ = useObservable(
        (input$) =>
            input$.pipe(
                filter(([config]) => !!config),
                map(([config]) => config),
            ),
        [apiConfig],
    );
    const api$ = useObservable(pluckFirst, [api]);
    // 如果要request時才給api資訊,可以在參數附加上api
    const [detectChangeHandler, detectChangeHandler$] = useObservableCallback<T & { api?: APIModel }>((event$) => {
        return event$;
    });

    const deps$ = useObservable((input$) => input$, deps);
    const fetch$ = useObservable(() =>
        combineLatest([detectChangeHandler$, host$]).pipe(
            withLatestFrom(api$, deps$),
            map(([[value, apiConfig], api, deps]) => ({ value, apiConfig, api: value.api || api, deps })),
            filter(({ apiConfig, api }) => {
                const apiConfigExist = !!apiConfig && !!apiConfig.host && apiConfig.host !== '';
                const apiExist = !!api && !!api.path && !!api.method;
                return apiConfigExist && apiExist;
            }),
            switchMap(({ value, apiConfig, api, deps }) => {
                if (Object.hasOwn(value, 'api')) {
                    delete value.api;
                }
                const request = requestHandler({ apiItem: api, value, deps });
                const config: AxiosRequestConfig = {
                    url: `${apiConfig?.host}${api?.path}`,
                    method: api?.method,
                    data: request,
                };
                return iif(
                    () => api?.method === 'FAKE',
                    of({ apiItem: api, value: { data: { Data: [] } }, deps, request }),
                    Axios.request<IResponse<R>>(config).pipe(
                        map((resp) => ({ apiItem: api, value: resp, deps, request })),
                        catchError((err) => {
                            errorHandler(err, deps);
                            return of(err);
                        }),
                    ),
                );
            }),
        ),
    );

    useSubscription(fetch$, responseHandler);

    return detectChangeHandler;
}
