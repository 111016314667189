import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IChatState {
    isPresented: boolean;
    unreadMessageCount: number;
}

const initialState: IChatState = {
    isPresented: false,
    unreadMessageCount: 0,
};

export const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        summonChat(state) {
            state.isPresented = true;
            return state;
        },
        dismissChat(state) {
            state.isPresented = false;
            return state;
        },
        updateUnreadMessageCount(state, action: PayloadAction<number>) {
            state.unreadMessageCount = action.payload;
            return state;
        },
    },
})

export const {
    summonChat,
    dismissChat,
    updateUnreadMessageCount,
} = chatSlice.actions;

export default chatSlice.reducer;
