import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import getAppStorageUtil, { STORAGE_KEYS } from '@/service/appStorageUtil';
import { IUserInfo } from '@/models/entities/IUserInfo';
import dayjs from 'dayjs';
import { RoleTypeEnum } from '@/models/page/logon/ILogon';

const [setItem, getItem, removeItem] = getAppStorageUtil();

const initialState: IUserInfo = {
    userName: getItem(STORAGE_KEYS.USERINFO) || '',
    agentPartners: getItem(STORAGE_KEYS.AGENTPARTNER_KEY) || [],
    token: getItem(STORAGE_KEYS.TOKEN),
    registerDate: getItem(STORAGE_KEYS.REGISTER_DATE) || '',
    roleType: getItem(STORAGE_KEYS.ROLE_TYPE) || RoleTypeEnum.NormalUser,
    permissions: getItem(STORAGE_KEYS.PERMISSIONS) || [],
    vChatId: getItem(STORAGE_KEYS.VCHAT_ID) || '',
    userVChatInfos: getItem(STORAGE_KEYS.USER_VCHAT_INFOS) || [],
    hasRechargeItems: getItem(STORAGE_KEYS.HAS_RECHARGE_ITEMS) || false,
    transactionTypeList: getItem(STORAGE_KEYS.TRANSACTION_TYPE_LIST) || [],
};

/**
 * Slice
 */
export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        updateUserName(state, action: PayloadAction<IUserInfo['userName']>) {
            state.userName = action.payload;
            setItem(STORAGE_KEYS.USERINFO, action.payload);
            return state;
        },
        updateAgentPartners(state, action: PayloadAction<IUserInfo['agentPartners']>) {
            state.agentPartners = action.payload;
            setItem(STORAGE_KEYS.AGENTPARTNER_KEY, action.payload);
            return state;
        },
        updateUserVChatId(state, action: PayloadAction<IUserInfo['vChatId']>) {
            state.vChatId = action.payload;
            setItem(STORAGE_KEYS.VCHAT_ID, action.payload);
            return state;
        },
        updateUserVChatInfos(state, action: PayloadAction<IUserInfo['userVChatInfos']>) {
            state.userVChatInfos = action.payload;
            setItem(STORAGE_KEYS.USER_VCHAT_INFOS, action.payload);
            return state;
        },
        updateHasRechargeOrder(state, action: PayloadAction<IUserInfo['hasRechargeItems']>) {
            state.hasRechargeItems = action.payload;
            setItem(STORAGE_KEYS.HAS_RECHARGE_ITEMS, action.payload);
            return state;
        },
        updateUserInfo(state, action: PayloadAction<IUserInfo>) {
            setItem(STORAGE_KEYS.USERINFO, action.payload.userName);
            setItem(STORAGE_KEYS.AGENTPARTNER_KEY, action.payload.agentPartners);
            setItem(STORAGE_KEYS.TOKEN, action.payload.token);
            setItem(STORAGE_KEYS.REGISTER_DATE, action.payload.registerDate);
            setItem(STORAGE_KEYS.TIMESTAMP, dayjs());
            setItem(STORAGE_KEYS.ROLE_TYPE, action.payload.roleType || RoleTypeEnum.NormalUser);
            setItem(STORAGE_KEYS.PERMISSIONS, action.payload.permissions || []);
            setItem(STORAGE_KEYS.VCHAT_ID, action.payload.vChatId || '');
            setItem(STORAGE_KEYS.USER_VCHAT_INFOS, action.payload.userVChatInfos || []);
            setItem(STORAGE_KEYS.HAS_RECHARGE_ITEMS, action.payload.hasRechargeItems || false);
            setItem(STORAGE_KEYS.TRANSACTION_TYPE_LIST, action.payload.transactionTypeList || false);

            state = action.payload;
            return state;
        },
        removeToken(state) {
            removeItem(STORAGE_KEYS.TOKEN);
            delete state.token;
            return state;
        },
    },
});

export const { updateUserName, updateAgentPartners, updateUserInfo, removeToken, updateUserVChatInfos, updateHasRechargeOrder, updateUserVChatId } =
    userSlice.actions;

/**
 * Reducer
 */
export default userSlice.reducer;
