import { DialogButtonType, DialogContentType, IButtonType, IDialogButtonSetting, IDialogProp } from '@/components/dialog/dialog';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type IDialogSetting = {
    title: string;
    content: string | JSX.Element;
    contentType?: DialogContentType;
    type: IButtonType;
    buttons?: IDialogButtonSetting[];
    buttonsAlign?: 'start' | 'center' | 'end';
    onClose?: () => void;
    onConfirm?: () => void;
};

const initialState: IDialogProp = {
    title: '',
    content: '',
    type: DialogButtonType.Info,
    isOpen: false,
};

/**
 * Slice
 */
export const dialogSlice = createSlice({
    name: 'dialog',
    initialState,
    reducers: {
        showDialog(state, action: PayloadAction<IDialogSetting>) {
            return {
                ...action.payload,
                isOpen: true,
            };
        },
        closeDialog() {
            return {
                ...initialState,
                isOpen: false,
            };
        },
    },
});

export const { showDialog, closeDialog } = dialogSlice.actions;

/**
 * Reducer
 */
export default dialogSlice.reducer;
