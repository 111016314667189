import { combineReducers } from '@reduxjs/toolkit';
import configReducer from './slices/config.slice';
import dialogReducer from './slices/dialog.slice';
import userReducer from './slices/user.slice';
import chatReducer from './slices/chat.slice';

const rootReducer = combineReducers({
    configStore: configReducer,
    dialogStore: dialogReducer,
    userStore: userReducer,
    chatStore: chatReducer,
});

export default rootReducer;
